// components/_menu.scss

.menu {
  &.-biz {
    background: #f5f5f5;
    color: #333;
    font-size: 0.75em;
    line-height: 1.15;
    font-weight: 700;
    padding: 0 0.75em;
    text-align: right;
    text-decoration: none;
    text-transform: uppercase;
    margin: 0 auto;
    max-width: 85.375rem;

    a {
      display: inline-block;
      padding: 0.71em 0.65em;
    }

    @media (max-width: 1024px) {
      display: none;
    }
  }

  &.-widgets {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &.-bars {
    width: 2.75em;
    height: 2.75em;
    background: transparent;
    border: 0;

    .sprite.-bars {
      height: 1.5em;
      fill: #1973b4;
      width: 1.5em;
    }

    @media (min-width: 1025px) {
      display: none;
    }
  }

  &.-overlay {
    display: none;
  }

  &.-main {
    display: none;
    font-size: 0.75em;
    font-weight: 700;
    justify-content: center;
    text-transform: uppercase;
    white-space: nowrap;
    z-index: 9;
    transition: left 0.3s ease-in-out;

    @media (min-width: 1024px) {
      display: block;
      height: 22px;
    }
  }

  &.-item {
    display: inline-block;
    padding: 0 17px 0 0;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
    cursor: pointer;

    &.vrum {
      margin: 0;
    }

    &.-title {
      padding-top: 0;
    }

    @media (min-width: 1025px) {
      &:hover {
        padding: 0 17px 30px 0;
      }
    }

    @media (max-width: 1024px) {
      z-index: 10;

      &:not(:last-child) {
        border-bottom: 1px solid #c2d1d9;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 1.25em 0.75em;

        svg {
          margin-top: 0.25rem;
        }
      }
    }

    @media (max-width: 1024px) {
      &:last-child {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 1.25em 0.75em;

        svg {
          margin-top: 0.25rem;
        }
      }
    }

    &:hover {
      .-submenu {
        @media (min-width: 1025px) {
          display: flex;
        }
      }

      .-chevrondown {
        @media (min-width: 768px) {
          transform: rotate(360deg);
        }
      }
    }

    &.-active {
      .-submenu {
        @media (max-width: 1024px) {
          display: flex;
        }
      }

      .-chevrondown {
        @media (max-width: 768px) {
          transform: rotate(360deg);
        }
      }
    }
  }

  &.-title {
    font-weight: 700;
    padding: 0.5em 0 0;
    text-transform: uppercase;
  }

  &.-submenu {
    display: none;
    flex-direction: column;
    font-weight: 400;
    line-height: 2.58;
    padding: 1em 3em 1em 1.5em;

    @media (max-width: 1024px) {
      z-index: 11;
      flex-direction: column;
      width: 100%;
    }

    @media (min-width: 1025px) {
      margin-top: 3em;
      position: absolute;
      top: 0;
      left: 0;
      background: #fff;
      border-radius: 4px;
      border-top: 3px solid #00660d;
      box-shadow: 0 0 2px 0 #666;

      &:hover {
        top: 0;
      }

      &::before {
        content: "";
        background: #fff;
        border: 3px solid #00660d;
        border-width: 3px 3px 0 0;
        transform: rotate(-45deg);
        position: absolute;
        top: -0.9em;
        left: 3.5em;
        width: 1.5em;
        height: 1.5em;
      }

      &::after {
        content: "";
        position: absolute;
        top: -1.5em;
        left: 0;
        width: 100%;
        height: 1.5em;
      }
    }

    a {
      display: block;
      padding: 0.45em 0;
      text-transform: uppercase;
    }

    @media (max-width: 1024px) {
      & > .-title {
        display: none;
      }
    }
  }

  &.-column {
    padding: 0;

    @media (max-width: 1024px) {
      padding: 1em 3em 1em 1.5em;
    }

    @media (min-width: 1025px) {
      flex-direction: row;
    }
  }

  &.-col {
    @media (min-width: 1025px) {
      padding: 1em 3em 1em 1.5em;
      border-radius: 4px;

      & + .-col {
        background: #f5f5f5;
      }
    }
  }

  .-item[class*="noticias -active"],
  .-item[class*="servicos -active"] {
    @media (max-width: 1024px) {
      color: #1c78bb;

      .-submenu {
        color: #1c78bb;
        border-color: #1c78bb;

        &::after,
        &::before {
          border-color: #1c78bb;
        }
      }
    }
  }

  .-item[class*="noticias"]:hover,
  .-item[class*="servicos"]:hover {
    @media (min-width: 1025px) {
      color: #1c78bb;

      .-submenu {
        color: #1c78bb;
        border-color: #1c78bb;

        &::after,
        &::before {
          border-color: #1c78bb;
        }
      }
    }
  }

  .-item[class*="esportes -active"] {
    @media (max-width: 1024px) {
      color: #00660d;

      .-submenu {
        color: #00660d;
        border-color: #00660d;

        &::after,
        &::before {
          border-color: #00660d;
        }
      }
    }
  }

  .-item[class*="esportes"]:hover {
    @media (min-width: 1025px) {
      color: #00660d;

      .-submenu {
        color: #00660d;
        border-color: #00660d;

        &::after,
        &::before {
          border-color: #00660d;
        }
      }
    }
  }

  .-item[class*="entretenimento -active"] {
    @media (max-width: 1024px) {
      color: #ed7d00;

      .-submenu {
        color: #ed7d00;
        border-color: #ed7d00;

        &::after,
        &::before {
          border-color: #ed7d00;
        }
      }
    }
  }

  .-item[class*="entretenimento"]:hover {
    @media (min-width: 1025px) {
      color: #ed7d00;

      .-submenu {
        color: #ed7d00;
        border-color: #ed7d00;

        &::after,
        &::before {
          border-color: #ed7d00;
        }
      }
    }
  }

  .-item[class*="parceiros -active"] {
    @media (max-width: 1024px) {
      color: #8b572a;

      .-submenu {
        color: #8b572a;
        border-color: #8b572a;

        &::after,
        &::before {
          border-color: #8b572a;
        }
      }
    }
  }
  .-item[class*="parceiros"]:hover {
    @media (min-width: 1025px) {
      color: #8b572a;

      .-submenu {
        color: #8b572a;
        border-color: #8b572a;

        &::after,
        &::before {
          border-color: #8b572a;
        }
      }
    }
  }

  .search {
    position: relative;

    .btn-header {
      z-index: 2;
      position: absolute;
      right: 0;
      top: -13px;
      background: transparent;
      border: none;
      cursor: pointer;
    }

    .input-header {
      position: absolute;
      top: -20px;
      right: 0;
      width: 320px;
      height: 40px;
      z-index: 1;
      border: 1px solid #c2d1d9;
      border-radius: 4px;

      font-size: 14px;
      font-weight: bold;
      line-height: 1.29;
      color: #9b9b9b;

      padding: 3px 36px 3px 6px;

      @media (max-width: 1024px) {
        width: 260px;
      }
    }

    .d-none {
      display: none;
    }
  }
}

@media (max-width: 1024px) {
  .menu {
    &.-main,
    &.-overlay {
      height: 0;
      position: fixed;
      width: 0;
      z-index: -1;
      visibility: hidden;
    }

    &.-overlay {
      display: block;
      background: transparent;
      transition: background 0.3s ease-in-out;
      content: none;
    }

    &.-main {
      display: flex;
      left: -280px;
      transition: left 0.3s ease-in-out;

      &.-opened {
        background: #fff;
        flex-flow: column nowrap;
        justify-content: start;
        overflow-x: hidden;
        width: 280px;
        left: 0;
        overflow-y: auto;
        z-index: 2;
        &,
        & + .-overlay {
          height: calc(100vh - 66px);
          top: 66px;
          visibility: inherit;
        }

        & + .-overlay {
          background: rgba(#333, 0.8);
          content: "";
          z-index: 1;
          left: 0;
          width: 100%;
        }
      }

      @media (max-width: 768px) {
        display: block;
        transition: all 0.3s ease-in-out;
        height: calc(100vh - 66px);
        top: 66px;
        visibility: inherit;
      }
    }
  }
}
