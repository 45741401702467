// components/_header.scss

.header {
  &.-wrapper {
    border-bottom: 1px solid #c2d1d9;
    margin-bottom: 1.75em;
    width: 100%;

    @media (max-width: 1024px) {
      margin-bottom: 0.625rem;
    }

    @media (max-width: 768px) {
      margin-bottom: 0;
    }
  }

  &.-grid {
    display: grid;
    gap: 1em;
    grid-template-areas:
      "social  logo  widgets"
      "menu    menu   menu";
    grid-template-columns: 1fr 100px 1fr;
    margin: 0 auto;
    max-width: 85.375rem;
    padding: 0.75em 16px 15px;

    @media (max-width: 1024px) {
      padding: 0.65em 0.625rem;
      grid-template-areas: "bars logo widgets";
    }
  }

  .social.-header {
    grid-area: social;
  }
  .logo.-main {
    grid-area: logo;
  }
  .menu.-widgets {
    grid-area: widgets;
  }
  .menu.-main {
    grid-area: menu;
  }
  .menu.-bars {
    grid-area: bars;
  }
}

.logo {
  &.-main {
    text-align: center;

    img {
      height: 3.75em;
      width: 5em;

      @media (max-width: 1024px) {
        width: 3.35em;
        height: 2.75em;
      }
    }
  }
}

.currency {
  &.-wrapper {
    margin-right: 2em;

    @media (max-width: 1024px) {
      display: none;
    }
  }

  &.-item {
    display: flex;
    font-size: 0.75em;
    font-weight: 700;
    justify-content: center;
    text-transform: uppercase;
  }

  &.-name {
    text-align: center;
    width: 7ch;
  }

  &.-down {
    color: #d0021b;
  }
  &.-up {
    color: #417505;
  }
}

.sprite {
  &.-down,
  &.-up {
    height: 0.42em;
    fill: currentColor;
    width: 0.67em;
  }

  &.-chevrondown {
    height: 0.75em;
    fill: currentColor;
    width: 1.1em;
    transform: rotate(270deg);
    transition: transform 0.3s ease-in-out;
    margin-bottom: 3px;
  }

  &.-chevronright {
    background-image: url("https://www.uai.com.br/imgs/uai/v1-1-9/chevron-right-gray.svg");
    width: 25px;
    height: 25px;
    margin-left: 13px;
  }

  &.-facebook,
  &.-twitter,
  &.-instagram,
  &.-youtube {
    display: block;
    height: 1.1em;
    fill: #fff;
    width: 1.1em;
  }

  &.-cloudsun {
    height: 2.5em;
    fill: currentColor;
    width: 2.5em;
  }

  &.-search,
  &.-user {
    height: 1.5em;
    fill: #1973b4;
    width: 1.5em;
  }
}

.social {
  &.-header {
    display: flex;
    align-items: center;

    @media (max-width: 1024px) {
      display: none;
    }
  }

  &.-facebook,
  &.-twitter,
  &.-instagram,
  &.-youtube {
    align-items: center;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    margin-right: 0.625em;
    height: 1.875em;
    width: 1.875em;
  }

  &.-facebook {
    background: #3b5998;
  }
  &.-twitter {
    background: #55acee;
  }
  &.-instagram {
    background: #6a453b;
  }
  &.-youtube {
    background: #c4302b;
  }
}

.weather {
  &.-wrapper {
    display: grid;
    gap: 0.15em 0.75em;
    grid-template-columns: 2.5em 1fr 1fr;
    grid-template-rows: auto 1fr;
    grid-template-areas: "icon city city" "icon min max";
    font-size: 0.75em;
    font-weight: 700;
    margin-right: 3em;
    text-transform: uppercase;

    @media (max-width: 1024px) {
      display: none;
    }
  }

  &.-city {
    grid-area: city;
  }

  &.-min {
    color: #2b73b4;
    grid-area: min;
  }

  &.-max {
    color: #d0021b;
    grid-area: max;
  }

  &.-icon {
    color: #1973b4;
    grid-area: icon;
  }
}

.search,
.user {
  &.-wrapper {
    margin: 0 0 0 0.5em;
  }
}

.user {
  display: none;
}

.fixed {
  @media (max-width: 1024px) {
    position: fixed;
    top: 0;
    width: 100%;
    background: #fff;
    z-index: 99999;
  }
}
