// generic/_critical.scss

// Imports ========================== */
@import "fonts";

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

html {
  @media (max-width: 1024px) {
    overflow-x: hidden;
  }
}

body {
  // font-weight: 300;
  // font-size: 1em;
  // line-height: 1.33;
  // font-family: 'Roboto', sans-serif;
  font: 300 1em/1.33 "Roboto", sans-serif;
  color: #333;
  overflow-x: hidden;

  @media (min-width: 640px) {
  }
}

a {
  color: inherit;
  text-decoration: none;
  // transition: color 0.3s ease-in-out;
}

img {
  max-width: 100%;
  border-radius: 2px;
}

p {
  margin: 0;
}
