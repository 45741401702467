// generic/_fonts.

// Roboto
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("https://www.uai.com.br/css/uai/v1-1-9/fonts/roboto-bold.woff2") format("woff2"),
    url("https://www.uai.com.br/css/uai/v1-1-9/fonts/roboto-bold.woff") format("woff");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("https://www.uai.com.br/css/uai/v1-1-9/fonts/roboto-regular.woff2") format("woff2"),
    url("https://www.uai.com.br/css/uai/v1-1-9/fonts/roboto-regular.woff") format("woff");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("https://www.uai.com.br/css/uai/v1-1-9/fonts/roboto-light.woff2") format("woff2"),
    url("https://www.uai.com.br/css/uai/v1-1-9/fonts/roboto-light.woff") format("woff");
}
